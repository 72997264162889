<template>
    
    <div><ProjectHeader></ProjectHeader>
           <section class="inner-page portfolio-details" style="padding-bottom : 0px">
      <div class="container" v-if="projectData">

        <div class="row gy-4">

          <div class="col-lg-8">
            <div class="portfolio-description">
              
              <span v-html="projectData.details"></span>
              
            </div>
          </div>

          <div class="col-lg-4">
            <div class="portfolio-info">

              <h3 style="font-size: 20px;">{{projectData.header}}</h3>
              <ul>
                <li class="row"><strong class="col-4">Years :</strong> <span class="col-8" style="padding:0">{{projectData.years}}</span></li>
                <li class="row"><strong class="col-4">Client :</strong> <span  class="col-8" style="padding:0">{{projectData.client}}</span></li>
                <li class="row"><strong class="col-4">Target :</strong> <span class="col-8" style="padding:0"><span  v-for="(target,index) in projectData.target" :key="index">{{parseInt(target.number).toLocaleString() +  " " + target.title}}  <br>  </span></span></li>
                
                <li class="row"><strong class="col-4">Regions :</strong> <span class="col-8" style="padding:0">{{projectData.regions}}</span></li>
                <li class="row" v-if="projectData.website"><strong class="col-4">Website :</strong> <a :href="projectData.website" target="_blank"><span class="col" style="padding:0">{{projectData.website}}</span></a></li>
                  <li><a :href="projectData.twitter" v-if="projectData.twitter" class="twitter social-media"><i class="bi bi-twitter"></i></a>
                    <a v-if="projectData.facebook" :href="projectData.facebook" class="facebook social-media"><i class="bi bi-facebook"></i></a>
                    <a v-if="projectData.instagram" :href="projectData.instagram" class="instagram social-media"><i class="bi bi-instagram"></i></a>
                    <a v-if="projectData.linkedin" :href="projectData.linkedin" class="linkedin social-media"><i class="bi bi-linkedin"></i></a></li>
                  
              </ul>
            </div>

          </div>

        </div>

      </div>







    </section>
    <section class="inner-page portfolio-details counts values" style="padding-bottom: 20px !important;">
        <div class="container" v-if="projectData">
<div class="portfolio-description " style="padding:0">
       
        
        <div class="row gy-4">

          <div class="col-lg-6 col-md-6" 
           style="cursor: pointer"
           v-for="(component,index) in projectData.components" :key="index"
           @click="routeTo('components',component)"
           >
            <div class=" component-item box">
              
              <div >
                <span class="bridges-components-headers about-button" style="margin-top:-25px">{{component.header}}</span>
                <p class="colapsed-five-line-text text-left" v-html="component.details">
                    
                </p>
              </div>
            </div>
          </div>

         

        </div>
        </div>
      </div>
    
      
    </section>
    </div>
</template>
<script>
import { getComponentData } from '../../assets/js/service';
import ProjectHeader from './Project.vue'

export default {
  components:{
  ProjectHeader
},
    data() {
        return {
            projectData:null,
        }
    },
    
    mounted() {
        
        this.projectData = this.$store.state.projectInformation;
   
    },
     watch: {
    "$store.state.projectInformation": {
      handler() {
        this.projectData = this.$store.state.projectInformation;
      },
    },
  },
    
    methods: {
        routeTo(route,ComponentData) {
      if(route == 'components'){
         getComponentData('Components',false,ComponentData.id,{main:'inerventions',sub:"inerventionSectors",last:"sector"}).then(resp=>{
          
          this.$store.commit("set",["componentData",resp.data]);
          this.$router.push('/components/'+ComponentData.id);
        })
        
        
      }else{
        this.$router.push(route);
      }
    },
        
    },
}
</script>
<style>
    .social-media{
      padding: 5px;
    }
</style>